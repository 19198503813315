import type { Translations } from 'shared/utils/translations';
import type {
  AccidentArea,
  VehicleDamageArea,
  VehicleDamagePart,
  VehicleDamageType,
  AccidentReason as AccidentReasonType,
} from 'gql/graphql';

export const REPAIRER_OEM_FRANCHISE_DEALERSHIP = 'OEM_FRANCHISE_DEALERSHIP';
export const REPAIRER_INDEPENDENT = 'INDEPENDENT';
export const REPAIRER_UNKNOWN = 'UNKNOWN';

export const ACCIDENT_REPAIRED = 'REPAIRED';
export const ACCIDENT_NOT_REPAIRED = 'NOT_REPAIRED';
export const ACCIDENT_POOR_OR_PARTIALLY_REPAIRED = 'POOR_OR_PARTIALLY_REPAIRED';

export const AccidentAreaRepairStatus = Object.freeze({
  Repaired: 'REPAIRED',
  PoorOrPartiallyRepaired: 'POOR_OR_PARTIALLY_REPAIRED',
  NotRepaired: 'NOT_REPAIRED',
});

export const AccidentAreaRepairStatusTranslations = Object.freeze({
  REPAIRED: 'a1-inspectionApp-accident-fully-repaired',
  POOR_OR_PARTIALLY_REPAIRED: 'a1-inspectionApp-accident-partially-repaired',
  NOT_REPAIRED: 'a1-inspectionApp-no',
});

export const AccidentReason = {
  PanelsWithHighPaintThickness: 'PANELS_WITH_HIGH_PAINT_THICKNESS',
  PanelsWithDifferentTones: 'PANELS_WITH_DIFFERENT_TONES',
  PanelsDeformedDueToDamage: 'PANELS_DEFORMED_DUE_TO_DAMAGE',
  PanelsReplacedDueToDamage: 'PANELS_REPLACED_DUE_TO_DAMAGE',
  BodyGapsOrMisalignedPanel: 'BODY_GAPS_OR_MISALIGNED_PANELS',
  UnderlyingStructureDeformed: 'UNDERLYING_STRUCTURE_DEFORMED',
  SignsOfStructuralRepair: 'SIGNS_OF_STRUCTURAL_REPAIR',
  WaterFireOrHailDamage: 'WATER_FIRE_OR_HAIL_DAMAGE',
  DatabaseProofOfAccident: 'DATABASE_PROOF_OF_ACCIDENT',
  InsuranceOrRepairInvoice: 'INSURANCE_OR_REPAIR_INVOICE',
  Other: 'OTHER',
} as const;

export const ACCIDENT_REPAIR_STATUS = (translations: Translations) => [
  {
    label: translations.ACCIDENT_FULLY_REPAIRED,
    value: ACCIDENT_REPAIRED,
  },
  {
    label: translations.ACCIDENT_PARTIALLY_REPAIRED,
    value: ACCIDENT_POOR_OR_PARTIALLY_REPAIRED,
  },
  {
    label: translations.NO,
    value: ACCIDENT_NOT_REPAIRED,
  },
];

export const ACCIDENT_REPAIRERS = (translations: Translations) => [
  {
    label: translations.ACCIDENT_REPAIRER_UNKNOWN,
    value: REPAIRER_UNKNOWN,
  },
  {
    label: translations.ACCIDENT_REPAIRER_INDEPENDENT,
    value: REPAIRER_INDEPENDENT,
  },
  {
    label: translations.ACCIDENT_REPAIRER_OEM,
    value: REPAIRER_OEM_FRANCHISE_DEALERSHIP,
  },
];

export const PAINT_THICKNESS_ACCIDENT_DAMAGE_PART_MAP: VehicleDamagePart[] = [
  // Front
  'FRONT_BUMPER',
  'FRONT_SPOILER',
  'RADIATOR',
  'FRONT_PARK_DISTANCE_CONTROL_SENSOR',
  'HOOD',
  'FRONT_WINDOW_FRAME',
  // Left
  'LEFT_SIDE_SKIRTS',
  'LEFT_MIRROR_HOUSING',
  'FRONT_LEFT_DOOR',
  'FRONT_LEFT_DOOR_HANDLE',
  'REAR_LEFT_DOOR',
  'REAR_LEFT_DOOR_HANDLE',
  'LEFT_FILLER_CAP',
  'FRONT_LEFT_FENDER',
  'LEFT_A_PILLAR',
  'LEFT_B_PILLAR',
  'LEFT_C_PILLAR',
  'LEFT_D_PILLAR',
  'REAR_LEFT_FENDER',
  // Rear
  'REAR_SPOILER',
  'REAR_BUMPER',
  'DIFFUSER',
  'REAR_PARK_DISTANCE_CONTROL_SENSOR',
  'TAILGATE',
  'REAR_PANEL',
  'REAR_WINDOW_FRAME',
  // Right
  'RIGHT_SIDE_SKIRTS',
  'RIGHT_MIRROR_HOUSING',
  'FRONT_RIGHT_DOOR',
  'FRONT_RIGHT_DOOR_HANDLE',
  'REAR_RIGHT_DOOR',
  'REAR_RIGHT_DOOR_HANDLE',
  'RIGHT_FILLER_CAP',
  'FRONT_RIGHT_FENDER',
  'RIGHT_A_PILLAR',
  'RIGHT_B_PILLAR',
  'RIGHT_C_PILLAR',
  'RIGHT_D_PILLAR',
  'REAR_RIGHT_FENDER',
  // Roof
  'ROOF_RAILS',
  'ROOF_CROSS_BARS',
  'ROOF',
  'PANORAMIC_ROOF',
  // Wheels
  'FRONT_LEFT_TIRE',
  'FRONT_LEFT_RIM',
  'REAR_LEFT_TIRE',
  'REAR_LEFT_RIM',
  'REAR_RIGHT_TIRE',
  'REAR_RIGHT_RIM',
  'FRONT_RIGHT_TIRE',
  'FRONT_RIGHT_RIM',
];

export const PAINT_THICKNESS_ACCIDENT_DAMAGE_TYPE_MAP: VehicleDamageType[] = [
  'REPAINT',
  'BODY_REPAINT',
  'ATTACHMENTS_REPAINT',
  'MOVABLE_BODY_REPAINT',
];

export const REASONS_WITH_DAMAGE_IMAGES = [
  AccidentReason.PanelsWithHighPaintThickness,
  AccidentReason.BodyGapsOrMisalignedPanel,
] as AccidentReasonType[];

export const MAP_ACCIDENT_AREA_TO_DAMAGE_AREA: Record<
  AccidentArea,
  Array<VehicleDamageArea>
> = {
  FRONT: ['FRONT'],
  ROOF: ['ROOF'],
  REAR: ['REAR', 'TRUNK'],
  INTERIOR: ['INTERIOR', 'CONTROLS'],
  UNDERBODY: ['UNDERBODY', 'ENGINE'],
  LEFT: ['LEFT'],
  RIGHT: ['RIGHT'],
};

export const MAP_ACCIDENT_AREA_TO_WHEELS_PART: Partial<
  Record<AccidentArea, Array<VehicleDamagePart>>
> = {
  LEFT: [
    'FRONT_LEFT_TIRE',
    'FRONT_LEFT_RIM',
    'REAR_LEFT_TIRE',
    'REAR_LEFT_RIM',
  ],
  RIGHT: [
    'FRONT_RIGHT_TIRE',
    'FRONT_RIGHT_RIM',
    'FRONT_LEFT_TIRE',
    'FRONT_LEFT_RIM',
  ],
};

import * as React from 'react';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';
import { Button, ButtonThemes } from '@auto1-ui/button';

import { FormToggleButton } from 'shared/components/FormToggleButton';
import { FormDropdown } from 'shared/components/FormDropdown';
import { FormUploader } from 'shared/components/FormUploader';
import { Card } from 'shared/components/Card';
import { Typography } from 'shared/components/Typography';
import { useTranslation } from 'shared/hooks/useTranslation';
import { processHeicFile } from 'shared/components/FormUploader/processHeicFile';
import { FormCheckbox } from 'shared/components/FormCheckbox';
import { FormNumberInput } from 'shared/components/FormNumberField';
import styles from '../index.module.scss';
import * as options from '../Service/options';
import { ACCIDENT_REPAIRERS } from '../constants';

type Props = {
  qaIdPrefix: string;
};

const Accidents: React.FC<Props> = ({ qaIdPrefix }) => {
  const { translations } = useTranslation();
  const yesOrNoToggle = options.yesOrNoToggle(translations);
  const { watch, setValue } = useFormContext();
  const [hasHadAccident, wasRepaired] = watch([
    'accident.hasHadAccident',
    'accident.wasRepaired',
  ]);

  const isRepairCostsAvailable = watch('accident.isRepairCostsAvailable');

  React.useEffect(() => {
    if (isRepairCostsAvailable) {
      setValue('accident.repairCost', null);
    }
  }, [isRepairCostsAvailable, setValue]);

  return (
    <Card
      paddingTop={32}
      paddingBottom={32}
      id="accidents"
      qaIdPrefix={qaIdPrefix}
      showBottomBorder
    >
      <div className={styles.title}>
        <Typography variant="titleXL" data-qa-id={`${qaIdPrefix}-title`}>
          {translations.ACCIDENT_HEADER}
        </Typography>
      </div>

      {/* WAS CAR IN ACCIDENT */}
      <div
        className={hasHadAccident ? styles.formElementContainer : ''}
        data-qa-id={`${qaIdPrefix}-car-in-accident-container`}
      >
        <FormToggleButton
          label={translations.ACCIDENT_QUESTION_HAD_ACCIDENT}
          containerClassName={styles.toggleButtonHorizontal}
          errorClassName={styles.toggleErrorHorizontal}
          qaIdPrefix={`${qaIdPrefix}-car-in-accident-toggle`}
          name="accident.hasHadAccident"
          options={yesOrNoToggle}
          defaultValue={null}
        />
      </div>

      {/* WAS CAR REPAIRED */}
      {hasHadAccident && (
        <>
          <div
            className={styles.formElementContainer}
            data-qa-id={`${qaIdPrefix}-car-is-repaired-toggle-container`}
          >
            <FormToggleButton
              label={translations.ACCIDENT_QUESTION_REPAIRED}
              containerClassName={styles.toggleButtonHorizontal}
              errorClassName={styles.toggleErrorHorizontal}
              qaIdPrefix={`${qaIdPrefix}-car-is-repaired-toggle`}
              options={yesOrNoToggle}
              name="accident.wasRepaired"
              defaultValue={null}
            />
          </div>

          <div
            className={cn(
              styles.subField,
              styles.halfWidthGrid,
              styles.textFieldContainer,
            )}
          >
            <div className={styles.halfWidthColumn}>
              <FormNumberInput
                label={
                  <>
                    {translations.ACCIDENT_QUESTION_COST}{' '}
                    <span className={styles.optionalLabel}>
                      {translations.OPTIONAL}
                    </span>
                  </>
                }
                name="accident.repairCost"
                className={styles.repairCostInput}
                containerClassName={styles.inputContainerFullWidth}
                disabled={isRepairCostsAvailable}
                qaIdPrefix={`${qaIdPrefix}-repaired-consts`}
                defaultValue={null}
              />

              <FormCheckbox
                name="accident.isRepairCostsAvailable"
                uiLabel={translations.NOT_AVALIABLE}
                qaIdPrefix={`${qaIdPrefix}-repair-cost-avaliable`}
                containerClassName={styles.checkBoxLabel}
                defaultValue={false}
              />
            </div>
            <div className={styles.halfWidthColumn}>
              {wasRepaired && (
                <FormDropdown
                  label={translations.ACCIDENT_QUESTION_REPAIRER}
                  name="accident.repairedBy"
                  qaIdPrefix={`${qaIdPrefix}-repaired-by`}
                  options={ACCIDENT_REPAIRERS(translations)}
                  defaultValue=""
                  containerClassName={styles.inputContainerFullWidth}
                />
              )}
            </div>
          </div>

          {wasRepaired && (
            <div
              className={cn(styles.uploadAccident, {
                [styles.hiddenField]: !hasHadAccident || !wasRepaired,
              })}
            >
              <FormUploader
                allowMultipleFiles
                uploadFileTypes="image/png, image/jpeg, image/heic"
                processSelectedFile={processHeicFile}
                qaIdPrefix="invoice-photos-uploader"
                inputQaId="invoice-photos-uploader-input"
                extraClassName={styles.uploaderStyles}
                name="accident.invoiceImages"
                defaultValue={[]}
              >
                {({ handleUploadClick }) => (
                  <>
                    <div className={styles.uploadCloudIcon} />
                    <Typography>{translations.DROP_PHOTOS_HERE}</Typography>
                    <Button
                      theme={ButtonThemes.link}
                      data-qa-id="invoice-photos-browse-button"
                      extraClass={styles.browseButton}
                      onClick={(event) => {
                        event.preventDefault();
                        handleUploadClick();
                      }}
                    >
                      {translations.BROWSE}
                    </Button>
                  </>
                )}
              </FormUploader>
            </div>
          )}
        </>
      )}
    </Card>
  );
};

export { Accidents };

import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import cn from 'classnames';
import type {
  AccidentArea,
  AccidentReason,
  AccidentAreaOption,
} from 'gql/graphql';
import { Card } from 'shared/components/Card';
import { Typography } from 'shared/components/Typography';
import { useTranslation } from 'shared/hooks/useTranslation';
import { CarImage } from './images/CarImage';
import { AreaButton } from './AreaButton';
import { Variation, Area, AREA_LABELS } from './constants';
import {
  mapAccidentDamageAreaToAccidentArea,
  clearValueFromDisabledOptions,
  areaIsEmpty,
} from '../../utils';
import type { VariationType } from './types';
import type { Form, ImageData, Damage } from '../../types';
import { ReasonsModal } from './ReasonsModal';
import { DeleteNotPossibleModal } from './DeleteNotPossibleModal';
import { DeleteModal } from './DeleteModal';
import { AreasList } from './AreasList';
import styles from './index.module.scss';

type Props = {
  qaIdPrefix?: string;
  accidentAreaOptions?: AccidentAreaOption[] | null;
  damages: Damage[];
};

function mapReasons(
  reasons: Partial<Record<AccidentReason, (File | ImageData)[] | null>>,
) {
  return reasons &&
    Object.values(reasons).length > 0 &&
    Object.values(reasons).some((reason) => !!reason)
    ? reasons
    : undefined;
}

const Areas: React.FC<Props> = ({
  qaIdPrefix = 'areas',
  accidentAreaOptions,
  damages,
}) => {
  const [showReasonModalForArea, setShowReasonModalForArea] =
    React.useState<AccidentArea | null>(null);
  const [deleteModal, setDeleteModal] =
    React.useState<React.ReactElement | null>(null);
  const {
    register,
    unregister,
    watch,
    setValue,
    trigger,
    getValues,
    formState: { errors, isSubmitted },
  } = useFormContext<Form>();
  const { translations, translate: t } = useTranslation();
  const errorAreaMessage = React.useMemo(() => errors?.areas, [errors?.areas]);

  const areas = watch('areas') as NonNullable<Form['areas']>;
  const hasErrors = errors?.areas ? true : false;
  const areasWithAccidentFromDamage =
    mapAccidentDamageAreaToAccidentArea(damages);
  const carImageAreas = Object.values(Area).reduce((acc, curr) => {
    acc[curr] =
      areas && curr in areas && !areaIsEmpty(curr, areas)
        ? Variation.Accident
        : areasWithAccidentFromDamage.includes(curr)
        ? Variation.Damage
        : Variation.Default;

    return acc;
  }, {} as Record<AccidentArea, VariationType>);
  const openReasonsModal = React.useCallback(
    (accidentArea: AccidentArea) => {
      setShowReasonModalForArea(accidentArea);
    },
    [setShowReasonModalForArea],
  );
  const closeReasonModal = React.useCallback(() => {
    setShowReasonModalForArea(null);
  }, [setShowReasonModalForArea]);
  const openDeleteModal = (accidentArea: AccidentArea) => {
    const hasAccidentFromDamage =
      areasWithAccidentFromDamage.includes(accidentArea);

    if (hasAccidentFromDamage) {
      setDeleteModal(
        <DeleteNotPossibleModal
          onClick={() => {
            setDeleteModal(null);
          }}
        />,
      );
    } else {
      setDeleteModal(
        <DeleteModal
          onClickDelete={() => {
            const areas = getValues('areas');
            delete areas[accidentArea];
            setValue('areas', areas);
            setDeleteModal(null);
          }}
          onClickKeep={() => {
            setDeleteModal(null);
          }}
        />,
      );
    }
  };

  React.useEffect(() => {
    register('areas');

    return () => {
      unregister('areas');
    };
  }, [register, unregister]);

  return (
    <>
      <Card
        paddingTop={32}
        paddingBottom={32}
        showBottomBorder
        id="areas"
        qaIdPrefix={qaIdPrefix}
      >
        <div className={styles.title}>
          <Typography variant="titleXL" data-qa-id={`${qaIdPrefix}-title`}>
            {translations.ACCIDENT_AREAS_SECTION_TITLE}
          </Typography>
        </div>
        <Typography tag="div" data-qa-id={`${qaIdPrefix}-description-1`}>
          {translations.ACCIDENT_AREAS_SECTION_DESCRIPTION_1}
        </Typography>
        <Typography tag="div" data-qa-id={`${qaIdPrefix}-description-2`}>
          {translations.ACCIDENT_AREAS_SECTION_DESCRIPTION_2}
        </Typography>
        <div className={styles.areaGrid} data-qa-id="areas-drawing">
          <div
            className={cn(styles.areaContainer, styles.car, {
              [styles.carError]: hasErrors,
            })}
          >
            <CarImage areas={carImageAreas} />
            {Object.values(Area)
              .filter(
                (area) =>
                  !([Area.Underbody, Area.Interior] as AccidentArea[]).includes(
                    area,
                  ),
              )
              .map((area) => (
                <AreaButton
                  key={area}
                  area={area}
                  label={t(AREA_LABELS[area])}
                  className={cn(styles.areaButton, styles[area])}
                  onAdd={openReasonsModal}
                  onDelete={() => openDeleteModal(area)}
                  variant={carImageAreas[area]}
                />
              ))}
          </div>
          <div
            data-qa-id="area-underbody"
            className={cn(
              styles.areaContainer,
              styles.areaNotVisible,
              styles.UNDERBODY,
              {
                [styles.areaNotVisibleAccident]:
                  !areaIsEmpty(Area.Underbody, areas) ||
                  carImageAreas[Area.Underbody] === Variation.Damage,
                [styles.carError]: hasErrors,
              },
            )}
          >
            <AreaButton
              area={Area.Underbody}
              label={t(AREA_LABELS[Area.Underbody])}
              onAdd={openReasonsModal}
              onDelete={() => openDeleteModal(Area.Underbody)}
              variant={carImageAreas[Area.Underbody]}
            />
          </div>
          <div
            className={cn(
              styles.areaContainer,
              styles.areaNotVisible,
              styles.INTERIOR,
              {
                [styles.areaNotVisibleAccident]:
                  !areaIsEmpty(Area.Interior, areas) ||
                  carImageAreas[Area.Interior] === Variation.Damage,
                [styles.carError]: hasErrors,
              },
            )}
          >
            <AreaButton
              area={Area.Interior}
              label={t(AREA_LABELS[Area.Interior])}
              onAdd={openReasonsModal}
              onDelete={() => openDeleteModal(Area.Interior)}
              variant={carImageAreas[Area.Interior]}
            />
          </div>
        </div>
        {errorAreaMessage &&
          'message' in errorAreaMessage &&
          errorAreaMessage.message && (
            <Typography variant="textXSRed" data-qa-id="areas-error-message">
              {errorAreaMessage.message}
            </Typography>
          )}
        <AreasList
          accidentAreaOptions={accidentAreaOptions ?? []}
          areas={areas}
        />
      </Card>
      {showReasonModalForArea && (
        <ReasonsModal
          qaIdPrefix="accident-reasons"
          accidentArea={showReasonModalForArea}
          selectedReasons={areas?.[showReasonModalForArea]}
          accidentAreaOptions={accidentAreaOptions}
          onSelectReasons={(reasons) => {
            setValue(`areas.${showReasonModalForArea}`, mapReasons(reasons));
            clearValueFromDisabledOptions(getValues(), setValue);
            closeReasonModal();

            if (isSubmitted) {
              trigger('areas');
            }
          }}
        />
      )}
      {deleteModal}
    </>
  );
};

export { Areas };

import type {
  AccidentArea,
  AccidentsInitialDataQuery,
  AccidentReason,
  VehicleAccidentArea,
  VehicleDamage,
} from 'gql/graphql';
import type { ReasonByArea, Form, ImageData } from './types';
import {
  ACCIDENT_NOT_REPAIRED,
  ACCIDENT_REPAIRED,
  ACCIDENT_POOR_OR_PARTIALLY_REPAIRED,
} from './constants';
import { shouldDisableReason } from './utils';

function mapAccidentAreasToForm(
  areas: VehicleAccidentArea[] | null | undefined,
  damages: VehicleDamage[] | null | undefined,
) {
  return areas?.reduce((acc, { area, reasons }) => {
    if (!reasons) {
      return acc;
    }

    acc[area] = reasons?.reduce<ReasonByArea>((acc2, { reason, images }) => {
      if (!reason) {
        return acc2;
      }

      if (shouldDisableReason(area, reason, damages ?? [])) {
        return acc2;
      }

      acc2[reason] =
        images && Array.isArray(images)
          ? images.map(({ __typename, ...image }) => image)
          : null;

      return acc2;
    }, {} as ReasonByArea);

    return acc;
  }, {} as Record<AccidentArea, ReasonByArea>);
}

const mapDataToFormValues = (
  inspection: AccidentsInitialDataQuery['inspection'],
): Form => {
  const accidents = inspection?.vehicle?.accidents;
  const damages = inspection?.vehicle?.damages ?? [];
  const hasHadAccident = inspection?.vehicle?.condition?.hasHadAccident;
  const hasDamageFromAccidents: true | null =
    damages?.some(({ isAccidentReason }) => isAccidentReason) === true
      ? true
      : null;
  const hasHadAccidentValue = hasDamageFromAccidents
    ? true
    : hasHadAccident ?? null;

  if (!Array.isArray(accidents)) {
    return {
      accidents: {
        hasHadAccident: hasHadAccidentValue,
      },
      areas: {},
      repair: {
        repairStatus: null,
        repairedBy: null,
        repairCost: null,
        isRepairCostAvailable: null,
        files: [],
        isTotalLoss: null,
      },
    };
  }

  const [accident] = accidents;
  if (!accident) {
    return {
      accidents: {
        hasHadAccident: hasHadAccidentValue,
      },
      areas: {},
      repair: {
        repairStatus: null,
        repairedBy: null,
        repairCost: null,
        isRepairCostAvailable: null,
        files: [],
        isTotalLoss: null,
      },
    };
  }

  let wasRepaired = null;
  if (accident.repairStatus !== null) {
    wasRepaired = accident.repairStatus !== ACCIDENT_NOT_REPAIRED;
  }

  return {
    accidents: {
      hasHadAccident: true,
    },
    repair: {
      repairStatus: accident.repairStatus ?? null,
      repairedBy: wasRepaired ? accident?.repairedBy ?? null : null,
      repairCost:
        typeof accident.repairCost?.amount === 'number' &&
        typeof accident.repairCost?.minors === 'number'
          ? accident.repairCost?.amount / accident.repairCost?.minors
          : null,
      isRepairCostAvailable:
        accident.isRepairCostAvailable !== null
          ? !accident.isRepairCostAvailable
          : null,
      files: accident.files ?? [],
      isTotalLoss: accident.isTotalLoss ?? null,
    },
    areas: mapAccidentAreasToForm(accident.areas, damages) ?? {},
  };
};

function mapUploadedFilesToObject(
  uploadedFiles: {
    area: string;
    reason: string;
    files: ImageData[];
  }[],
) {
  return uploadedFiles.reduce((acc, { area, reason, files }) => {
    acc[area] = acc[area] ?? {};
    acc[area][reason] = files;

    return acc;
  }, {} as { [key: string]: { [key: string]: ImageData[] } });
}

const mapAccidentAreasToMutation = (
  areas: Partial<Record<AccidentArea, ReasonByArea | null>>,
  uploadedFiles: { area: string; reason: string; files: ImageData[] }[],
) => {
  const files = mapUploadedFilesToObject(uploadedFiles);

  return (Object.entries(areas) as Array<[AccidentArea, ReasonByArea]>)
    .filter(([_, reasons]) => !!reasons)
    .map(([area, reasons]) => ({
      area,
      reasons: (
        Object.entries(reasons) as Array<[AccidentReason, (File | ImageData)[]]>
      )
        .filter(([_, images]) => !!images)
        .map(([reason, images]) => ({
          reason,
          images: images ? files[area][reason] ?? [] : null,
        })),
    }))
    .filter(({ reasons }) => reasons.length > 0);
};

const mapAccidentToMutation = (data: Form) => {
  const {
    accidents: { hasHadAccident },
    repair,
    areas,
  } = data;

  const repaired =
    hasHadAccident &&
    repair.repairStatus &&
    [ACCIDENT_REPAIRED, ACCIDENT_POOR_OR_PARTIALLY_REPAIRED].includes(
      repair.repairStatus,
    );

  return {
    hasHadAccident,
    repairStatus: repair?.repairStatus ?? null,
    repairCost: !repair?.isRepairCostAvailable ? repair?.repairCost : null,
    isRepairCostsAvailable:
      !repair?.isRepairCostAvailable && !repair?.repairCost
        ? null
        : !repair?.isRepairCostAvailable,
    repairedBy: repaired ? repair?.repairedBy || null : undefined,
    files: repair?.files,
    isTotalLoss: repair?.isTotalLoss,
    areas,
  };
};

export {
  mapDataToFormValues,
  mapAccidentAreasToMutation,
  mapAccidentToMutation,
  mapAccidentAreasToForm,
};
